@import "css/global.sass";
@import url(https://fonts.googleapis.com/css?family=Montserrat);

/***************************************
 GLOBAL STYLES
 ***************************************/

.transition-blocker * {
    transition: none !important; }

html, body, #container {
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;

    background-color: $theme-bg;
    font-family: "Times New Roman", serif;
    color: $theme-font; }

a {
    color: darken($theme-font, 20%); }

#internet-explorer-warning {
    width: 100%;
    position: fixed;
    background-color: red;
    color: white;
    padding: 20px;
    z-index: 999999;
    opacity: 0.8; }

/***************************************
 SIDEBAR STYLES
 ***************************************/

#sidebar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    @include is-not-compact {
        height: 100%;
        width: $sidebar-width; }
    @include is-compact {
        width: 100%;
        height: $sidebar-height-compact; }

    background-color: $theme-gold;
    color: #fff;

    transition: all 0.5s ease;

    #logo img {
        @include is-not-compact {
            margin-left: 10px;
            margin-right: 10px;
            width: calc(#{$sidebar-width} - 20px);
            height: calc(#{$sidebar-width} - 20px); }
        @include is-compact {
            width: $sidebar-height-compact;
            height: $sidebar-height-compact; } }

    #menu-toggle {
        flex-grow: 1;

        cursor: pointer;
        visibility: visible;

        @include responds-to-hover;

        @include is-not-compact {
            // Display a ^ MENU ^ label along the left
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                font-size: 14px; }

            width: 100%;
            height: 100%; }
        @include is-compact {
            // Display a hamburger icon in the top-right
            width: $sidebar-height-compact;
            height: $sidebar-height-compact;

            position: fixed;
            float: right;
            right: 0;

            background-image: url(../img/menu.png);
            background-size: contain;
            content: "";

            span {
                display: none; } }

        transition: all 0.2s ease; }

    #social {
        width: $sidebar-width;
        height: 40px;

        cursor: pointer;
        @include responds-to-hover;

        background-image: url(../img/social.png);
        background-size: contain;
        background-position: center center;

        @include is-compact {
            display: none; } }

    #menu, #menu-close {
        opacity: 0;
        position: absolute;

        visibility: hidden; }

    #menu {
        transition: all 0.2s ease;
        transition-delay: 0s;

        @include is-not-compact {
            top: ($sidebar-width + 15px);
            left: -10px; }
        @include is-compact {
            top: ($sidebar-height-compact + 10px);
            left: -5px; }

        @include limited-dynamic-font-size(3.3, 35);

        // List of links
        ul {
            list-style: none;
            li {
                padding-bottom: 20px;
                text-decoration: none;
                a {
                    text-decoration: none;
                    color: white; } } } }

    #menu-close {
        transition: all 0.3s ease;
        transition-delay: 0s;

        @include is-not-compact {
            top: 20px;
            left: ($sidebar-width * 3 - 30px); }
        @include is-compact {
            float: right;
            left: calc(100% - 100px);
            top: 0px; }

        cursor: pointer;

        @include is-not-compact {
            width: 30px;
            height: 30px; }
        @include is-compact {
            width: $sidebar-height-compact;
            height: $sidebar-height-compact; }

        background-image: url(../img/cross.png);
        background-size: contain; }

    &.open {
        @include is-not-compact {
            width: ($sidebar-width * 4); }
        @include is-compact {
            width: 100%;
            height: 100%; }

        #menu-toggle {
            opacity: 0;
            visibility: hidden; }

        #menu, #menu-close {
            transition-delay: 0.3s;

            opacity: 100;
            visibility: visible; }

        #menu {
            left: 20px; }

        #menu-close {
            @include is-not-compact {
                float: left;
                left: ($sidebar-width * 4 - 50px); }
            @include is-compact {
                left: calc(100% - 50px); } } }

    #screen-shade {
        position: fixed;
        left: 0;
        top: 0;

        width: 100vw;
        height: 100vw;

        background-color: black;
        opacity: 0.5;

        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
        &.visible {
            opacity: 0.5;
            visibility: visible; } }

    #social-popup {
        position: fixed;
        left: calc(50% - 240px);
        top: calc(50% - 240px);
        z-index: 100;
        padding: 20px;

        width: 480px;
        height: 480px;

        background-color: white;

        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;

        &.visible {
            opacity: 1;
            visibility: visible; }

        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .social-item {
            b {
                display: block;
                color: $theme-font;
                font-size: 1.2rem;
                text-transform: uppercase; }

            span {
                color: $theme-font;
                font-size: 2rem; } }

        #social-close {
            position: absolute;
            left: calc(50% + 200px);
            top: calc(50% - 230px);

            z-index: 101;

            width: 30px;
            height: 30px;

            background-image: url(../img/blackcross.png);
            background-size: contain;
            cursor: pointer;

            display: block; } } }

/***************************************
 GENERIC PAGE CONTENT STYLES
 ***************************************/

#content {
    @include is-not-compact {
        padding-left: $sidebar-width; }
    @include is-compact {
        padding-top: $sidebar-height-compact; }

    box-sizing: border-box;
    background-color: $theme-bg;

    .text-body {
        font-size: 1.15rem;
        background-color: white;
        text-align: center;
        line-height: 1.8rem;

        @include is-not-compact {
            margin: 20px;
            width: calc(100% - 40px); }
        @include is-compact {
            margin: 0px;
            width: 100%; }

        padding: 30px;

        @include is-not-compact {
            padding-left: 15vw;
            padding-right: 15vw; }

        box-sizing: border-box; // This fixes padding

        .main-title, h1 {
            text-align: center;
            font-size: 2rem;
            font-weight: bold;

            padding-top: 20px;
            padding-bottom: 20px; }

        .sub-title, h2 {
            text-align: center;
            font-size: 1.6rem;
            padding-top: 10px;
            padding-bottom: 10px; }

        .divider, hr {
            width: calc(100% - 100px);
            height: 1px;
            background-color: #aaa;
            margin-left: 50px;
            margin-right: 50px;
            margin-top: 50px;
            margin-bottom: 30px;

            box-sizing: border-box; } }


    #footer a {
        padding: 20px;
        float: right;
        color: grey; } }

.header-image {
    width: 100%;
    @include is-not-compact {
        height: 90vh; }
    @include is-compact {
        height: calc(100vh - #{$sidebar-height-compact}); }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    // Centre the inner text
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    color: #fff;
    text-align: center;

    .top, .middle, .bottom {
        text-shadow: 2px 2px #555; }

    .top {
        @include limited-dynamic-font-size(5, 80); }

    span.line {
        @include limited-dynamic-width(17, 200);
        height: 1px;
        background-color: white;
        display: inline-block;
        margin-left: 20px;
        margin-right: 20px;
        vertical-align: middle; }

    .middle {
        @include limited-dynamic-font-size(3, 30); }

    .bottom {
        @include limited-dynamic-font-size(9, 100);
        text-transform: uppercase; }

    #attribution-container {
        align-self: flex-end;
        height: 0;
        text-align: right;

        #attribution {
            padding: 2px;
            background-color: #8a8a8aaa;
            position: relative;
            vertical-align: top;
            top: -25px;
            left: -5px;
            color: white;

            &.showing {
                top: -45px; } } } }
